import React from "react";
import { Link } from "gatsby";
import showdown from "showdown";
import Content from "../Hacks/Content";
const converter = new showdown.Converter();

export const LearnMore = ({ title, cards, background}) => {
  const size = cards.length > 3 ? 3 : cards.length === 2 ? 6 : 4;

  return (
    <section id="learnmore" className={`learnmore container-fluid bg-${
        background ? background : "primary"} -text-center-`}>
      {title && (
        <div className="row contained-xl mx-auto justify-content-center pt-5">
          <div className="pb-lg-5 px-0 col-11 col-lg-8 col-xl-7">
            <h2 className="h1 mt-lg-5 mb-4 text-center">{title}</h2>
          </div>
        </div>
      )}
      <div className="row contained-xl mx-auto justify-content-center">
        <div className="pb-5 col-lg-11 col-xl-9">
          <div className="row justify-content-center">
            {cards &&
              cards.map(function (card, i) {
                return (
                  <div
                    className={`col-md-10 col-lg-${size} mb-4`}
                    key={`card_` + i}
                  >
                    <div
                      className={`${
                        background === "none" ? "" : "card"
                      } rounded h-100 p-3 bg-white`}
                    >
                      <div className="card-body">
                        <h3
                          className={`h2 mt-0 mb-2 text-${card.color} text-center text-lg-left`}
                        >
                          {card.title}
                        </h3>
                        <h4 className={`text-center text-lg-left mb-2`}>
                          {card.subtitle}
                        </h4>
                        <p>{card.intro}</p>

                        <Content
                          className={`mb-3 fa-list-checks fa-list-${card.color}`}
                          content={converter.makeHtml(card.content)}
                        />

                        {card.link && (
                          <p className="text-center text-lg-left mb-0">
                            <Link
                              cover={`true`}
                              direction="left"
                              className={`btn btn-${card.color} stretched-link mt-1 mt-3`}
                              to={card.link}
                            >
                              Learn More{" "}
                              <span className="sr-only">
                                about our {card.title} solution
                              </span>
                            </Link>
                            {/* <br />
                        <Link
                          cover={`true`}
                          direction="left"
                          bg="#1F1A3C"
                          className="btn text-white mt-1 mt-3 btn-sm"
                          to="/contact/"
                        >
                          Get Demo
                        </Link> */}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMore;
