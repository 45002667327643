import React from "react";
import ReactPlayer from "react-player";

export const Video = ({
  options,
  thumbnail,
  background = "transparent",
  image,
  full_width,
  video,
  caption,
  controls,
  muted,
  playing,
  heading,
  intro,
}) => {
  // console.log("Props in Video:", { heading, intro });

  if (!options) {
    options = [];
  }

  return (
    <section className={`video py-5 bg-${background}`}>
      <div className={"container-fluid py-lg-5"}>
        <div className="news-inner row justify-content-center contained-xl -align-items-center-">
          {heading && ( 
            <div className="col-lg-4">
              <h2 className="text-center text-lg-left text-secondary">{heading}</h2>
              <p className="text-center text-lg-left text-white">{intro}</p>
            </div>
          )}
          <div
            className={
              heading
                ? "col-lg-6"
                : full_width === "true"
                ? "col-lg-10"
                : "col-lg-8"
            }
          >
            <div className="shadow-lg rounded-sm embed-responsive embed-responsive-16by9">
              <ReactPlayer
                url={video}
                controls={controls}
                className="react-player embed-responsive-item"
                width="100%"
                height="100%"
                playing={playing}
                light={
                  image
                    ? image[0] === "/"
                      ? image
                      : image.publicURL
                      ? image.publicURL
                      : thumbnail
                      ? thumbnail
                      : false
                    : false
                }
                muted={muted}
                playsinline={true}
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
              />
            </div>
            {caption && <p className="small text-center mt-3">{caption}</p>}
          </div>
        </div>
      </div>
    </section>
    
  );
  
};

export default Video;
