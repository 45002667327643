import React from "react";
import { Link } from "gatsby";
import FAQQuestion from "./FAQQuestion";

export const FAQBlock = ({ faqs, ctaUrl, ctaText, title, background="transparent" }) => {
  return (
    <section className={`faq container-fluid -bg-${background}- position-relative py-5`}>
      {title && <h2 className="h3 mb-4 pt-5 text-center">{title}</h2>}

      <div className="row contained-xl justify-content-center ">
        <div className="col-12 col-md-8 mt-5">
          <ul id="accordion" className="fa-ul mt-0 row ">
            {faqs.map(function (faq, i) {
              return (
                <li
                  className={`col-lg-6 text-md-left mb-3 collapse_link${i}`}
                  key={"faq_" + i}
                >
                  <FAQQuestion question={faq.question} answer={faq.answer} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-12 text-center">
          {ctaUrl && (
            <Link
              cover={`true`}
              direction="right"
              bg="#1F1A3C"
              className="btn btn-success mt-3 btn-lg main-cta"
              to={ctaUrl}
            >
              {ctaText}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default FAQBlock;
